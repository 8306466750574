import { createContext, useContext } from "react"
import { DashboardState, DashboardStateAction, InitialDashboardState } from "../reducer/dashboardReducer"

export type DashboardStateContextType = {
    dashboardState: DashboardState,
    dispatch: React.Dispatch<DashboardStateAction>
}

export const DashboardStateContext = createContext<DashboardStateContextType>({
    dashboardState: InitialDashboardState,
    dispatch: () => { },
});

export const useDashboardState = () => useContext(DashboardStateContext);