import { LatLng } from "leaflet";
import { BoolDeviceProperty, DeviceProperty, NumberDeviceProperty, StringDeviceProperty } from "../deviceProperty";
import { Device, DeviceType } from "./device";

namespace UBX {
    export type digitalIOMapKeys = 'din1' | 'din2' | 'dout1' | 'dout2';
    export type gmsMapKeys = 'gsm_carrier' | 'gsm_signal_strength' | 'gsm_iccid';
    export type licenceMapKeys = 'bluetooth' | 'data' | 'navigation';
    export type fugMapKeys =
        'fug_state' | 'fug_mode' | 'fug_vol' | 'folder_name' | 'fug_manufacturer' |
        'c_reg_state' | 'cell_id' | 'gssi_name' | 'tetra_gssi' | 'issi' | 'rssi' |
        'tetra_status' | 'tetra_net' | 'tetra_opta';
}

export class UBX extends Device {
    getPosition(): LatLng | undefined {
        return undefined;
        // if (!this.PosLatitude || !this.PosLongitude) return undefined;
        // return new LatLng(this.PosLatitude, this.PosLongitude);
    }

    // Common
    softwareVersion: string = 'unbekannt';

    // Voltages
    voltageProperties: [
        DeviceProperty<number>,
        DeviceProperty<number>,
        DeviceProperty<number>,
    ] = [
            new NumberDeviceProperty('VDD'),
            new NumberDeviceProperty('VIN1'),
            new NumberDeviceProperty('VIN2'),
        ]

    // Digital IO
    digitalIO: Map<UBX.digitalIOMapKeys, DeviceProperty<boolean>> = new Map([
        ['din1', new BoolDeviceProperty('din1')],
        ['din2', new BoolDeviceProperty('din2')],
        ['dout1', new BoolDeviceProperty('din1')],
        ['dout2', new BoolDeviceProperty('din2')],
    ]);

    // FuG
    fug: Map<UBX.fugMapKeys, DeviceProperty<any>> =
        new Map<UBX.fugMapKeys, DeviceProperty<any>>(
            [
                ['fug_state', new NumberDeviceProperty('fug_state')],
                ['fug_mode', new StringDeviceProperty('fug_mode')],
                ['fug_vol', new NumberDeviceProperty('fug_vol')],
                ['folder_name', new StringDeviceProperty('folder_name')],
                ['fug_manufacturer', new NumberDeviceProperty('fug_manufacturer')],

                ['c_reg_state', new NumberDeviceProperty('c_reg_state')],
                ['cell_id', new StringDeviceProperty('cell_id')],
                ['gssi_name', new StringDeviceProperty('gssi_name')],
                ['tetra_gssi', new StringDeviceProperty('tetra_gssi')],
                ['issi', new StringDeviceProperty('issi')],
                ['rssi', new NumberDeviceProperty('rssi')],

                ['tetra_status', new NumberDeviceProperty('tetra_status')],
                ['tetra_net', new StringDeviceProperty('tetra_net')],
                ['tetra_opta', new StringDeviceProperty('tetra_opta')],
            ]
        );

    // Vehicle

    // GSM
    gsm: Map<UBX.gmsMapKeys, DeviceProperty<number | string>> =
        new Map<UBX.gmsMapKeys, DeviceProperty<number | string>>(
            [
                ['gsm_carrier', new StringDeviceProperty('gsm_carrier')],
                ['gsm_iccid', new StringDeviceProperty('gsm_iccid')],
                ['gsm_signal_strength', new NumberDeviceProperty('gsm_signal_strength')],
            ]
        )

    // Licences
    licences: Map<UBX.licenceMapKeys, DeviceProperty<boolean>> = new Map(
        [
            ['bluetooth', new BoolDeviceProperty('Bluetooth')],
            ['data', new BoolDeviceProperty('Data')],
            ['navigation', new BoolDeviceProperty('Navigation')],
        ]
    );


    constructor(
        name: string,
        id: string,
        params: {
            softwareVersion?: string,
            // deviceState?: DeviceState,
            lastUpdate?: number,

            // voltages
            VDD?: number,
            VIN0?: number,
            VIN1?: number,

            // digital IO
            din_1?: boolean,
            din_2?: boolean,
            dout_1?: boolean,
            dout_2?: boolean,

            // FuG
            fug_state?: number,
            fug_mode?: string,
            fug_vol?: number,
            folder_name?: string,
            fug_manufacturer?: number,

            c_reg_state?: number,
            cell_id?: string,
            gssi_name?: string,
            tetra_gssi?: string,
            issi?: string,
            rssi?: number,
            tetra_status?: string,

            tetra_net?: string,
            tetra_opta?: string,

            // GSM
            gsm_carrier?: string;
            gsm_signal_strength?: string;
            gsm_iccid?: string;

            // licences
            bluetoothLicence?: boolean,
            dataLicence?: boolean,
            navigationLicence?: boolean,
        }
    ) {
        // TODO(flo,BUG,PRIO1) get date of last update
        super(name, DeviceType.ubx, id, Date.now());

        if (params.softwareVersion) this.softwareVersion = params.softwareVersion;

        // Voltages
        this.voltageProperties[0].setValue(params.VDD);
        this.voltageProperties[1].setValue(params.VIN0);
        this.voltageProperties[2].setValue(params.VIN1);

        // DigitalIO
        this.digitalIO.get("din1")?.setValue(params.din_1);
        this.digitalIO.get("din2")?.setValue(params.din_2);
        this.digitalIO.get("dout1")?.setValue(params.dout_1);
        this.digitalIO.get("dout2")?.setValue(params.dout_2);

        // FuG
        this.fug.get('fug_state')?.setValue(params.fug_state)
        this.fug.get('fug_mode')?.setValue(params.fug_mode)
        this.fug.get('fug_vol')?.setValue(params.fug_vol)
        this.fug.get('folder_name')?.setValue(params.folder_name)
        this.fug.get('fug_manufacturer')?.setValue(params.fug_manufacturer)

        this.fug.get('c_reg_state')?.setValue(params.c_reg_state)
        this.fug.get('cell_id')?.setValue(params.cell_id)
        this.fug.get('gssi_name')?.setValue(params.gssi_name)
        this.fug.get('tetra_gssi')?.setValue(params.tetra_gssi)
        this.fug.get('issi')?.setValue(params.issi)
        this.fug.get('rssi')?.setValue(params.rssi)

        this.fug.get('tetra_status')?.setValue(params.tetra_status)
        this.fug.get('tetra_net')?.setValue(params.tetra_net)
        this.fug.get('tetra_opta')?.setValue(params.tetra_opta)

        // Vehicle

        // GSM
        this.gsm.get('gsm_carrier')?.setValue(params.gsm_carrier);
        this.gsm.get('gsm_iccid')?.setValue(params.gsm_iccid);
        this.gsm.get('gsm_signal_strength')?.setValue(params.gsm_signal_strength);

        // Licences
        this.licences.get('bluetooth')?.setValue(params.bluetoothLicence);
        this.licences.get('data')?.setValue(params.dataLicence);
        this.licences.get('navigation')?.setValue(params.navigationLicence);
    }

    // update this device with new data
    update() { }

    // getState(): DeviceState {
    //     let state = DeviceState.OK;

    //     for (let i = 0; i < this.voltageProperties.length; i++) {
    //         // assert(i < this.voltageProperties.length);
    //         let newState = this.voltageProperties.at(i)!.getDeviceState();
    //         if (newState < state) state = newState;
    //     }

    //     // if (this.voltageProperties[1].value <= 12.0) return DeviceState.WARNING;
    //     return state;
    // }
    // getState(): DeviceState {
    //     return DeviceState.NO_STATE;
    // }
}