import NBXWidget from "../../widgets/dashboard/nbx";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import UBXWidget from "../../widgets/dashboard/ubx";
import { UBX } from "../../models/devices/ubx";
import { NBX } from "../../models/devices/nbx";

function Widgets() {
    const { dashboardState } = useDashboardState();

    let devices: JSX.Element[] = [];
    dashboardState.devices.forEach((device, id) => {
        if (device instanceof UBX) {
            devices.push(<UBXWidget key={id} model={device} />);
            return;
        }
        if (device instanceof NBX) {
            devices.push(<NBXWidget key={id} model={device} />);
            return;
        }

        // if we have a device, which is neither a nbx nor an ubx...
        devices.push(<p>Ick wees ja net</p>);
    });



    return (
        <div className="dashboard-body">
            <h1>Geräte:</h1>
            <div className="devices-list">
                {devices}

            </div>
        </div>
    )
}

export default Widgets;