import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { DeviceProperty } from "../../models/deviceProperty";

// [ ] Add settings for alignment
type CollapsableDeviceTableProps = {
    title: string
    // make this more easily to use
    details: (DeviceProperty<boolean | number | string>)[] | Map<string, DeviceProperty<boolean | number | string>>
}

export function CollapsableDeviceTable(props: CollapsableDeviceTableProps) {
    const [open, setOpen] = useState<boolean>(true);
    return (
        <>
            <h2>
                {props.title}
                <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </h2>
            <Collapse in={open}>
                <Table>
                    <TableBody>
                        {props.details instanceof Map
                            ?
                            [...props.details.entries()].map((v, idx) => {
                                return (DeviceTableRow(idx, v[1]))
                            })
                            :
                            props.details.map((entry, idx) => {
                                if (!entry) return <></>
                                return (
                                    DeviceTableRow(idx, entry)
                                )
                            }
                            )}
                    </TableBody>
                </Table>
            </Collapse>
        </>
    );
}

function DeviceTableRow(id: number, deviceProperty: DeviceProperty<boolean | number | string>): React.JSX.Element {
    return (
        <TableRow key={id}>
            <TableCell width={'30%'} align="left">{deviceProperty.name}</TableCell>
            <TableCell width={'70%'} align="center">{deviceProperty.representation()}</TableCell>
        </TableRow>
    )
}