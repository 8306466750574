import { Button, Stack } from "@mui/material";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { VerticalSpacer } from "../../widgets/spacer";
import { Logout, SelectOrganisationAction } from "../../reducer/login";
import { getUserRoleName } from "../../models/organisation";


function SelectOrganisationPage() {

    const { dashboardState, dispatch } = useDashboardState();

    return (
        <div className="organisation-select-page">

            {/* organisations */}
            <Stack>
                {
                    dashboardState.organisations.map((o) =>
                        <Button
                            variant='outlined'
                            key={o.organisationID}
                            onClick={(_) => dispatch(new SelectOrganisationAction(o.organisationID))}
                        >{o.organisationName}, Rolle: {getUserRoleName(o.role)}</Button>
                    )
                }
            </Stack >

            <VerticalSpacer height={100} />

            {/* close button */}
            <Button
                onClick={() => dispatch(new Logout())}
            > Abmelden </Button>
            {/* <div
                onClick={() => dispatch(new CloseWebsocket())}
                className='organisation-select-logout-button'
            >Abmelden</div> */}
        </div>
    )
}

export default SelectOrganisationPage;