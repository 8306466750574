import React, { useEffect, useState } from 'react';

import { useDashboardState } from '../../contexts/dashboardStateContext';
import { FormControl, TextField, Button, Alert } from '@mui/material';
import { VerticalSpacer } from '../../widgets/spacer';
import { Login, SetLoginErrorMessageAction } from '../../reducer/login';


function LoginPage() {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(true);

    const { dashboardState, dispatch } = useDashboardState();



    useEffect(() => {
        setLoginButtonDisabled(!username || !password);
    }, [username, password])

    // TODO(flo, FEATURE, PRIO3) react to event and not to change of errorMessage
    useEffect(() => {
        setTimeout(() => {
            dispatch(new SetLoginErrorMessageAction(''));
        }, 5000);
    }, [dashboardState.errorMessage, dispatch])



    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(new Login(username, password, dispatch));
    }

    return (
        <div className="login-page" style={{ flexShrink: 0 }}>
            <main className='login-page-content'>
                <form onSubmit={onSubmit}>
                    <FormControl>
                        <TextField
                            type='text'
                            variant='outlined'
                            value={username}
                            autoComplete='username'

                            label="Benutzername"
                            onChange={e => setUsername(e.target.value)}
                        />

                        <VerticalSpacer height={16} />

                        <TextField
                            type='password'
                            variant='outlined'
                            value={password}
                            autoComplete='password'

                            label="Passwort"
                            onChange={e => setPassword(e.target.value)}
                        />

                        <VerticalSpacer height={16} />

                        <Button
                            variant='contained'
                            type='submit'
                            disabled={loginButtonDisabled}
                        >
                            Login
                        </Button>

                        <VerticalSpacer height={16} />
                        {dashboardState.errorMessage !== '' && <Alert severity='error'>{dashboardState.errorMessage}</Alert>}
                    </FormControl>
                </form>

            </main>
        </div>
    );
}

export default LoginPage;