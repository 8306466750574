import { Link } from "react-router-dom";
import { SvgIconComponent } from "@mui/icons-material";
// import { SvgIcon } from "@mui/material"; 

interface NavbarItemProps {
    icon: SvgIconComponent,
    title: string,
    path: string,
}
function NavbarItem(props: NavbarItemProps) {
    return (
        <Link to={props.path} style={{ textDecoration: 'none' }}>
            <div className="custom-navbar-item">
                <props.icon className="custom-navbar-item-icon" />
                <div className="custom-navbar-item-caption">
                    <h4> {props.title} </h4>
                </div>
            </div>
        </Link>
    )
}

export default NavbarItem;