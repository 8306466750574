interface HorizontalSpacerProps {
    width: number;
}

interface VerticalSpacerProps {
    height: number;
}

// function Spacer(props: SpacerProps): props is VerticalSpacerProps {
export function HorizontalSpacer(props: HorizontalSpacerProps) {
    return (
        <div></div>
    )
}

export function VerticalSpacer(props: VerticalSpacerProps) {
    return (
        <div style={{ height: props.height }}></div>
    )
}