// import { useState } from "react";
import { useNavigate } from "react-router";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { getUserRoleName } from "../../models/organisation";
import { useEffect } from "react";

// TODO(flo, FEAUTURE, PRIO3) include the logo
function DashboardHeader() {
    const { dashboardState } = useDashboardState();
    const navigate = useNavigate();

    useEffect(() => {
        if (dashboardState.organisations.length === 0 || dashboardState.organisations[0] == null) {
            console.log('navigating...')
            navigate('/logout')
        }
    }, [dashboardState.organisations, navigate])

    const organisationName: string = dashboardState.organisations[0]?.organisationName ?? '';
    const userRole: string = getUserRoleName(dashboardState.organisations[0]?.role) ?? '';

    return (
        <header className="dashboard-page-main-header">
            <h1 className="text-light" style={{ flexGrow: 6 }}>{organisationName}</h1>
            <h3 className="text-light" style={{ flexGrow: 2 }}>{dashboardState.username} - {userRole}</h3>
            <div className="col"></div>
        </header>
    )
}

export default DashboardHeader;