import { useState } from "react";
import { useDashboardState } from "../../contexts/dashboardStateContext";
// import { UpdateSettingsAction, getUserRoleName } from "../../reducer/dashboardReducer";
import { CollapsableEditableTable } from "../../widgets/dashboard/collapsable_editable_table";
import { CollapsableTable } from "../../widgets/dashboard/collapsable_table";
import FloatingButton from "../../widgets/floatingButton";
import { log_text } from "../../log";
import { UpdateSettingsAction } from "../../reducer/login";

// TODO(flo, PRIO1,FEATURE) implement the update button and actions
export default function SettingsPage(): JSX.Element {
    const { dispatch, dashboardState } = useDashboardState();
    // const { dashboardState } = useDashboardState();

    const [password, setPassword] = useState<string>("");
    const [email, setEmail] = useState<string>(dashboardState.userEMail);
    log_text(`userEmail: , ${dashboardState.userEMail}`);

    return (
        <>
            <div className="dashboard-body">
                <h1>Einstellungen</h1>
                {/* <div>
                    {dashboardState.notifications.toString()}
                </div> */}

                <CollapsableTable
                    title="Benutzer"
                    details={[
                        ["Benutzername", dashboardState.username],
                        // ["Rolle", getUserRoleName(dashboardState.userrole)],
                        ["Organisation", dashboardState.organisations[0]?.organisationName],
                        // dashboardState.userrole === UserRole.admin ? ["admin", "jip"] : null,
                    ]}
                />

                <CollapsableEditableTable
                    title="Einstellungen"
                    details={[
                        { label: "E-Mail", value: email, setValue: setEmail },
                        { label: "Passwort", hidable: true, value: password, setValue: setPassword, placeholder: "Passwort nicht ändern" },
                        // { label: "Label", value: "Value" }
                    ]}
                />

                <FloatingButton label="Speichern" onclick={() => {
                    // alert('[MOCK] Einstellungen wurden gespeichert')

                    dispatch(new UpdateSettingsAction({ newEmail: email, newPassword: password }));

                }} />
            </div>
        </>
    )
}