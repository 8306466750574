import 'leaflet/dist/leaflet.css';
import { useDashboardState } from "../../contexts/dashboardStateContext"
import { MultiDeviceMap } from '../../widgets/dashboard/multi_device_map';
import { MultiDeviceStateOverview } from './multi_device_state_overview_widget';

function Dashboard() {
    const { dashboardState } = useDashboardState();
    // let devices: { deviceName: string, latitude?: number, longitude?: number }[] = [];

    // dashboardState.devices.forEach(device => {
    // if (device instanceof NBX) {
    //     devices.push({
    //         deviceName: device.device_name ?? device.device_id,
    //         latitude: device.latitude,
    //         longitude: device.longitude,
    //     })
    // }

    // if (device instanceof UBX) {
    // }
    // })

    return (
        <div className="dashboard-body">
            <div className="overview">
                <h1>
                    Herzlich Willkommen, {dashboardState.username}
                </h1>
                {/* <NBXOverview />
                <UBXOverview /> */}
                <MultiDeviceStateOverview devices={dashboardState.devices} />
                <MultiDeviceMap Devices={dashboardState.devices} />
            </div>
        </div>
    )
}

export default Dashboard