export const TEN_DAYS_IN_MILLISECONDS: number = 1000 * 60 * 60 * 24 * 10;

const enum DebugLevels {
    NONE = 0,
    LOG = 1,
    DEBUG = 2,
    ERROR = 3,
    PANIC = 4,
}

export const DEBUG_LEVEL: DebugLevels = DebugLevels.ERROR;