export abstract class Notification {
    databaseID: string;
    notificationID: string;
    username: string;


    constructor(props: {
        databaseID: string,
        notificationID: string,
        username: string,
    }) {
        this.databaseID = props.databaseID;
        this.notificationID = props.notificationID;
        this.username = props.username;
    }
}

export class EmptyNotification extends Notification {
    constructor() {
        super(
            {
                databaseID: 'emptyDatabaseID',
                notificationID: 'emptyNotificationID',
                username: 'emptyUsername',
            }
        );
    }
}

export class TelegramNotification extends Notification {
    channelID: string;
    apiToken: string;

    constructor(props: {
        databaseID: string
        notificationID: string,
        username: string

        apiToken: string,
        channelID: string,
    }) {
        super({ databaseID: props.databaseID, notificationID: props.notificationID, username: props.username });
        this.apiToken = props.apiToken;
        this.channelID = props.channelID;
    }

}