import { useDashboardState } from "../contexts/dashboardStateContext";
import { Logout as LogoutAction } from "../reducer/login";

export default function Logout() {
    const { dispatch } = useDashboardState();
    setTimeout(() => {
        dispatch(new LogoutAction());
    }, 250);
    return (<h1>
        Ausloggen
    </h1>)
}