import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";

// [ ] Add settings for alignment
type CollapsableTableProps = {
    title: string
    // make this more easily to use
    details: ([string, any] | null)[]
}

export function CollapsableTable(props: CollapsableTableProps) {
    const [open, setOpen] = useState<boolean>(true);
    return (
        <>
            <h2>
                {props.title}
                <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </h2>
            <Collapse in={open}>
                <Table>
                    <TableBody>
                        {props.details.map((entry, idx) => {
                            if (!entry) return <></>
                            return (
                                <TableRow key={idx}>
                                    <TableCell width={'30%'} align="left">{entry[0]}</TableCell>
                                    <TableCell width={'70%'} align="center">{entry[1]}</TableCell>
                                </TableRow>)
                        }
                        )}
                    </TableBody>
                </Table>
            </Collapse>
        </>
    );
}