import { useDashboardState } from "../../contexts/dashboardStateContext";
import { isMoreThan10DaysOld } from "../../helper/date";
import { UBX } from "../../models/devices/ubx";
import { CollapsableDeviceTable } from "../../widgets/dashboard/collapsable_device_table";
import { CollapsableTable } from "../../widgets/dashboard/collapsable_table";
import createSendCommand from "../../widgets/dashboard/send_command";
import { VerticalSpacer } from "../../widgets/spacer";
import { useState } from "react";
import { SingleDeviceMap } from "../../widgets/dashboard/device_map";
import { ModalTextDialog } from "../../widgets/modalTextDialog";

interface UBXWidgetProps {
    model: UBX;
}
export default function UBXPage(props: UBXWidgetProps) {
    const { dashboardState } = useDashboardState();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const lastUpdateString: string =
        isMoreThan10DaysOld(props.model.lastUpdate)
            ? 'nie'
            : new Date(props.model.lastUpdate!).toLocaleString()

    return (
        <div className="dashboard-body">
            <h1>
                {/* {dashboardState.userrole === UserRole.admin &&
                    <IconButton onClick={() => { setModalIsOpen(true) }}><Build /></IconButton>
                } */}
                Name: {props.model.device_name}
            </h1>
            {/* <h2>ID: {props.model.object_id}</h2> */}

            <CollapsableTable title="UBX" details={[
                ['Version', props.model.softwareVersion],
                ['Zuletzt gesehen', lastUpdateString],
            ]} />


            <VerticalSpacer height={16} />
            <CollapsableDeviceTable title="Spannungen" details={
                props.model.voltageProperties
            } />

            <VerticalSpacer height={16} />
            <CollapsableDeviceTable title="Digital I/O" details={
                props.model.digitalIO
            } />

            <VerticalSpacer height={16} />

            <CollapsableDeviceTable title="Funkgerät" details={
                props.model.fug
            } />

            <VerticalSpacer height={16} />
            <CollapsableDeviceTable title="GSM" details={
                props.model.gsm
            } />

            <VerticalSpacer height={16} />
            {/* <CollapsableTable title="Lizenzen" details={[
                ['Bluetooth', `${props.model.licences.get('bluetooth')?.representation() ?? 'nein'}`],
                ['Data', `${props.model.licences.get('data')?.representation() ?? 'nein'}`],
                ['Navi', `${props.model.licences.get('navigation')?.representation() ?? 'nein'}`],
            ]} /> */}
            <CollapsableDeviceTable title="Lizenzen" details={
                props.model.licences
            } />

            <VerticalSpacer height={16} />

            <SingleDeviceMap
                Device={{
                    latitude: 50.14950565290054,
                    longitude: 9.143091729977394,
                    deviceName: props.model.device_name,
                }}
            />

            <VerticalSpacer height={16} />

            {/* // [ ] use conditional widget */}
            {createSendCommand(dashboardState, props.model.object_id)}

            {
                modalIsOpen &&
                <ModalTextDialog
                    isOpen={modalIsOpen}
                    setIsOpen={setModalIsOpen}
                    initialText={props.model.device_name}
                    // onSubmit={(v) => { dispatch(new UpdateDeviceNameAction(props.model.id, v)) }}
                    onSubmit={() => { }}
                />
            }
        </div>
    )
}