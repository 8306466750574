import { NBX } from "../../models/devices/nbx";
import { CollapsableTable } from "../../widgets/dashboard/collapsable_table";
import { SingleDeviceMap } from "../../widgets/dashboard/device_map";
import { VerticalSpacer } from "../../widgets/spacer";
import { isMoreThan10DaysOld } from "../../helper/date";
import { CollapsableDeviceTable } from "../../widgets/dashboard/collapsable_device_table";
import { NumberDeviceProperty } from "../../models/deviceProperty";

interface NBXWidgetProps {
    model: NBX;
}

export default function NBXPage(props: NBXWidgetProps) {
    // const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const lastUpdateString: string =
        isMoreThan10DaysOld(props.model.lastUpdate)
            ? 'nie'
            : new Date(props.model.lastUpdate!).toLocaleString()

    return (
        <div className="dashboard-body">

            <h1>
                {/* {dashboardState.userrole === UserRole.admin &&
                    <IconButton onClick={() => { setModalIsOpen(true) }}><Build /></IconButton>
                } */}
                Name: {props.model.device_name}
            </h1>
            {/* <h2>ID: {props.model.object_id}</h2> */}

            <CollapsableTable title="NBX" details={[
                // ['Version', props.model.UBXVersion],
                ['Letztes Update', lastUpdateString],
            ]} />


            <VerticalSpacer height={16} />
            <CollapsableDeviceTable title="Spannungen" details={
                [
                    new NumberDeviceProperty('VDD', props.model.VDD),
                    new NumberDeviceProperty('Vin0', props.model.Vin0),
                    new NumberDeviceProperty('Vin1', props.model.Vin1),
                    new NumberDeviceProperty('Vin2', props.model.Vin2),
                    new NumberDeviceProperty('Vin3', props.model.Vin3),
                    // props.model.voltageProperties
                ]
            } />

            <VerticalSpacer height={16} />

            <CollapsableTable title="Funkgerät" details={[
                ['RSSI', props.model.TetraRSSI],
                ['Reg-Status', props.model.CregState],
            ]} />

            <VerticalSpacer height={16} />

            <SingleDeviceMap
                Device={{
                    deviceName: props.model.device_name,
                    latitude: props.model.PosLatitude,
                    longitude: props.model.PosLongitude,
                }}
            />

            <VerticalSpacer height={16} />

            {/* // [ ] use conditional widget */}
            {/* {createSendCommand(dashboardState)} */}

            {
                // modalIsOpen &&
                // <ModalTextDialog
                //     isOpen={modalIsOpen}
                //     setIsOpen={setModalIsOpen}
                //     initialText={props.model.name}
                // // onSubmit={(v) => { dispatch(new UpdateDeviceNameAction(props.model.id, v)) }}
                // />
            }
        </div>
    )
}