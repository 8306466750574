
export enum UserRole { invalid, monitor, owner, admin };

export function getUserRoleName(userrole: UserRole | undefined): string {
    switch (userrole) {
        case UserRole.admin:
            return 'Administrator';
        case UserRole.monitor:
            return 'Monitor';
        case UserRole.owner:
            return 'Besitzer';
        default:
            return 'Unbekannt';
    }
}

export function getUserRoleFromString(userrole: string): UserRole {
    switch (userrole) {
        case 'admin':
            return UserRole.admin;
        case 'monitor':
            return UserRole.monitor;
        case 'owner':
            return UserRole.owner;
        default:
            return UserRole.invalid;
    }
}

export function getUserRoleFromNumber(userrole: number): UserRole {
    switch (userrole) {
        case 0:
            return UserRole.owner;
        case 1:
            return UserRole.admin;
        case 2:
            return UserRole.monitor;
        default:
            return UserRole.invalid;
    }
}

export class Organisation {
    organisationID: string;
    organisationName: string;
    role: UserRole;

    constructor(
        organisationID: string,
        organisationName: string,
        role: number,
    ) {
        this.organisationID = organisationID;
        this.organisationName = organisationName;
        this.role = getUserRoleFromNumber(role);

    }
}