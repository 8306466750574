import { useDashboardState } from "../../contexts/dashboardStateContext";
import { TelegramNotification } from "../../models/notifications";
import FloatingButton from "../../widgets/floatingButton";
import { useDebug } from "../../contexts/debugContext";
import { TelegramNotificationWidget } from "../../widgets/notifcations/telegramNotificationWidget";
import { Button } from "@mui/material";

export default function NotificationsPage(): JSX.Element {
    let notifications: JSX.Element[] = [];

    const { dashboardState } = useDashboardState();
    let { debug } = useDebug();

    dashboardState.notifications.forEach((notification, id) => {
        if (notification instanceof TelegramNotification) {
            notifications.push(<TelegramNotificationWidget key={id} notification={notification} />);
        }
    });

    return (
        <div className="dashboard-body">

            <div className="notification-page-header">
                <h1>Benachrichtigungen</h1>
                <Button
                    variant="contained"
                    onClick={() => {
                        // dispatch(new QueryNotificationsAction());
                    }}
                >Aktualisieren</Button>
            </div>
            {notifications}
            {
                debug ?
                    <FloatingButton label="Add Notification" /> : <></>
            }
        </div>
    )
}
