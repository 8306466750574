import React from "react";
import { sendWSRequest } from "../helper/websocket";
import { Organisation } from "../models/organisation";
import { DashboardState, DashboardStateAction, ResetDashboardState } from "./dashboardReducer";
import { HandleWebsocketMessage, SetWebsocket, UnsetWebsocket } from "./websocket";

const WS_URL = 'wss://dev1.status3.it/ws';
// const WS_URL = 'wss://dev1.status3.it/tc-ws';
// const WS_URL = 'ws://localhost:8091/v1/ws';

export class Login implements DashboardStateAction {
    username: string;
    password: string;
    dispatch: React.Dispatch<DashboardStateAction>;
    updateState: (state: DashboardState) => DashboardState = (s) => {
        // const navigate = useNavigate();
        if (s.websocket !== null) {
            return { ...s }
        }

        let websocket = new WebSocket(WS_URL);
        this.dispatch(new SetWebsocket(websocket));
        websocket.onmessage = (msg) => {
            this.dispatch(new HandleWebsocketMessage(msg.data));
        }
        websocket.onclose = () => {
            this.dispatch(new UnsetWebsocket());
            // navigate('/login')
        }
        websocket.onopen = () => {
            sendWSRequest(websocket, 'login', {
                'username': this.username,
                'password': this.password,
            })
        }


        return { ...s }
    };

    constructor(username: string, password: string, dispatch: React.Dispatch<DashboardStateAction>) {
        this.username = username;
        this.password = password;
        this.dispatch = dispatch;
    }
}

export class Logout implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        s.websocket?.close();
        s.websocket = null;
        s = {
            ...s,
            websocket: null,
        }
        s = new ResetDashboardState().updateState(s);
        return s;
    };
}

export class SetLoginErrorMessageAction implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        return {
            ...s,
            errorMessage: this.errorMessage,
        }
    }
    errorMessage: string;

    constructor(errorMessage: string) {
        this.errorMessage = errorMessage;
    }
}
export class SetUsernameAction implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        return {
            ...s,
            username: this.username,
        }
    };
    username: string;

    constructor(username: string) {
        this.username = username;
    }
}


export class SelectOrganisationAction implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        let organisations: Organisation[] = s.organisations.filter((o) => o.organisationID === this.organisationID)

        if (s.websocket === null) {
            return {
                ...s,
                organisations: organisations
            }
        }

        sendWSRequest(s.websocket, 'devices_for_organisation', {
            'organisation_id': organisations[0]!.organisationID,
        })

        return {
            ...s,
            organisations: organisations
        }
    };
    organisationID: string;

    constructor(organisationID: string) {
        this.organisationID = organisationID;
    }
}

export class UpdateSettingsAction implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        // let emailWasUpdated: boolean = false;
        if (this.newEmail && this.newEmail !== s.userEMail) {
            // emailWasUpdated = true;
            s.websocket?.send(JSON.stringify({
                'type': 'request',
                'topic': 'update_email',
                'value': this.newEmail,
            }))
        }

        if (this.newPassword)
            s.websocket?.send(JSON.stringify({
                'type': 'request',
                'topic': 'update_password',
                'value': this.newPassword,
            }))

        // if (emailWasUpdated) {
        return {
            ...s,
            userEMail: this.newEmail ?? s.userEMail
        }
        // }
        // return {
        //     ...s,
        // }
    };

    newPassword: string | undefined;
    newEmail: string | undefined;

    constructor(props: {
        newEmail?: string,
        newPassword?: string,
    }
    ) {
        this.newPassword = props.newPassword;
        this.newEmail = props.newEmail;
    }
}