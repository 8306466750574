import { icon, LatLng } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import iconURL from "leaflet/dist/images/marker-icon.png";
import React, { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, Link } from "@mui/icons-material";
// import { Link } from "react-router-dom";
import { Device } from "../../models/devices/device";

export interface MultiDeviceMapProps {
    Devices: Map<String, Device>
}

export function MultiDeviceMap(props: MultiDeviceMapProps) {
    const [open, setOpen] = useState<boolean>(true);
    // const [homePosition, setHomePosition] = useState<LatLng>(new LatLng(50.1431, 9.1438));
    const [homePosition, setHomePosition] = useState<LatLng | undefined>();

    // only necessary for multiple devices
    console.log(props.Devices.entries)
    if (props.Devices.size === 0) return (
        <h1>Kein Ger&auml;t gefunden</h1>
    );

    // if (props.Devices.entries().every(([, device]: [any, Device]) => device.getPosition() === undefined)) {
    //     return <h1>Keine Ger&auml;tedaten vorhanden</h1>
    // }
    if (Array.from(props.Devices).every(([, device]: [any, Device]) => device.getPosition() === undefined)) {
        return <h1>Keine Positionsdaten vorhanden</h1>
    }

    // if (props.Devices.every(device => { return device.latitude === undefined && device.longitude === undefined; })) {
    //     return <h1>Keine Positionsdaten vorhanden</h1>
    // }

    // let positions: { position: [number, number], deviceName: string }[] =
    //     props.Devices
    //         .filter(d => (d.latitude && d.longitude))
    //         .map(device => {
    //             return {
    //                 position: [device.latitude!, device.longitude!],
    //                 deviceName: device.deviceName,
    //             }
    //         });

    return (
        <>
            <h2>
                Karte
                <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </h2>
            <Collapse in={open}>
                <MapContainer style={{ width: '100%', height: '500px' }} center={homePosition ?? new LatLng(50.1431, 9.1438)} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {/* {[
                        {
                            'deviceName': 'device 01',
                            'deviceID': 'deviceID',
                            'position': new LatLng(50.1432, 9.1432)
                        },
                        {
                            'deviceName': 'device 02',
                            'deviceID': 'deviceID 2',
                            'position': new LatLng(50.1402, 9.1452)
                        }
                    ].map((d, idx) => CreateMarkerForDevice(d.deviceName, d.deviceID, d.position, idx))} */}
                    {
                        Array.from(props.Devices).map(([deviceID, device], idx) => {
                            if (device.getPosition() === undefined) {
                                return <></>
                            }
                            if (!homePosition) {
                                setHomePosition(device.getPosition()!);
                            }
                            return CreateMarkerForDevice(device.device_name, deviceID.toString(), device.getPosition()!, idx)
                        })
                    }


                </MapContainer>
            </Collapse >
        </>
    )
}

function CreateMarkerForDevice(deviceName: string, deviceID: string, position: LatLng, idx: number): React.JSX.Element {
    return <Marker key={idx} position={position} icon={icon({
        iconUrl: iconURL,
        iconAnchor: [10, 41],
        popupAnchor: [2, -41],
    })}>
        <Popup>
            <Link to={`/dashboard/device/${deviceID}`}>{deviceName}</Link>{deviceID}
        </Popup>
    </Marker>
}