import { DashboardState, DashboardStateAction } from "./dashboardReducer";
import { Organisation } from "../models/organisation";
import { Device, intToDeviceType } from "../models/devices/device";
import BuildDevice from "../models/devices/builder";
import { sendWSRequest } from "../helper/websocket";

export class SetWebsocket implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        return {
            ...s,
            websocket: this.websocket,
        }
    };
    websocket: WebSocket;

    constructor(webscoket: WebSocket) {
        this.websocket = webscoket;
    }
}

export class UnsetWebsocket implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => {
        return {
            ...s,
            websocket: null,
        }
    };
}



export class CloseWebsocket implements DashboardStateAction {
    updateState: (state: DashboardState) => DashboardState = (s) => s;
}

export class HandleWebsocketMessage implements DashboardStateAction {
    message: string;

    updateState: (state: DashboardState) => DashboardState = (s) => {
        interface IWebsocketMessage {
            type: string,
            topic: string,
            data: any,
            success: boolean,
        }
        let websocketMessage: IWebsocketMessage;

        try {
            websocketMessage = JSON.parse(this.message);
        } catch (e) {
            console.log('error while parsing json: ', e)
            return s;
        }

        let messageType: string = websocketMessage.type;
        let messageTopic: string = websocketMessage.topic;
        let data: object = websocketMessage.data;

        switch (messageType) {
            case 'info':
                return this.handleInfoMessage(messageTopic, s, data);

            case 'request':
                return this.handleRequestMessage(messageTopic, s, data)

            case 'response':
                return this.handleResponseMessage(messageTopic, s, data, websocketMessage.success);
        }
        return s;

    }
    handleInfoMessage(topic: string, s: DashboardState, data: object): DashboardState {
        return {
            ...s,
            websocketMessages: [...s.websocketMessages, data.toString()]
        }
    }

    handleRequestMessage(topic: string, s: DashboardState, data: object): DashboardState {
        return s;
    }

    handleResponseMessage(topic: string, s: DashboardState, data: any, success: boolean): DashboardState {
        switch (topic) {
            case 'login':
                return this.handleLoginResponseMessage(s, data, success);
            case 'devices':
                return this.handleDevicesResponseMessage(s, data, success);
        }
        return s;

        //     case 'devices':
        //         if (jsonMessage['success'] === false) {
        //             return {
        //                 ...state
        //             }
        //         }
        //         let jsonDevices: object[] = JSON.parse(jsonMessage['value']).devices;

        //         // devices needs to be cleared, so devices don't spill over from old logins
        //         state.devices.clear();

        //         // [?] check if this works
        //         jsonDevices.forEach((device: any) => {
        //             updateDevice(state, device);
        //         })

        //         return {
        //             ...state,
        //             devices: state.devices
        //         }

        //     case 'deviceUpdate':
        //         let jsonDevice: object = JSON.parse(jsonMessage['value']);
        //         updateDevice(state, jsonDevice);
        //         return {
        //             ...state,
        //         }

        //     case 'notifications':
        //         if (!jsonMessage['success']) {
        //             break;
        //         }
        //         if (jsonMessage['value'] === null) {
        //             break;
        //         }
        //         let jsonNotifications: object[] = JSON.parse(jsonMessage['value']).notifications;
        //         state.notifications = [];
        //         jsonNotifications.forEach((notification) => {
        //             updateNotification(state, notification);
        //         })

        //         return {
        //             ...state,
        //             notifications: state.notifications,
        //         }

        //     case 'organisationName':
        //         if (!jsonMessage['success']) {
        //             break;
        //         }
        //         return {
        //             ...state,
        //             organisationID: jsonMessage['value'],
        //         };

        //     case 'username':
        //         if (!jsonMessage['success']) {
        //             break;
        //         }
        //         return {
        //             ...state,
        //             username: jsonMessage['value'],
        //         }

        //     case 'userrole':
        //         if (!jsonMessage['success']) {
        //             break;
        //         }
        //         return {
        //             ...state,
        //             userrole: getUserRoleFromString(jsonMessage['value'])
        //         }
        //     case 'useremail':
        //         if (!jsonMessage['success']) {
        //             break;
        //         }
        //         return {
        //             ...state,
        //             userEMail: jsonMessage['value']
        //         }

        //     default:
        //         break;
        // }
        // return state;



    }

    handleLoginResponseMessage(s: DashboardState, data: {
        user_id: string,
        username: string,
        organisations: any,
        user_email: string,
    }, success: boolean): DashboardState {
        if (success === false) {
            s.websocket?.close();
            s.websocket = null;
            return {
                ...s,
                errorMessage: 'Ungültige Zugangsdaten'
            }
        }
        const userID = data.user_id;
        const username = data.username;
        const userEmail = data.user_email;
        let organisations: Organisation[] = []
        for (let orga in data.organisations) {
            organisations.push(new Organisation(
                orga,
                data.organisations[orga].organisation_name,
                data.organisations[orga].user_role,
            ));
        }

        if (organisations.length === 1) {

            sendWSRequest(s.websocket!, 'devices_for_organisation', {
                'organisation_id': organisations[0]!.organisationID,
            })
        }

        return {
            ...s,
            userID: userID,
            username: username,
            userEMail: userEmail,
            organisations: organisations,
        }
    }

    handleDevicesResponseMessage(
        s: DashboardState,
        data: {
            devices: any,
        },
        success: boolean
    ): DashboardState {
        if (success === false) {
            return {
                ...s,
            }
        }

        // interface DataShizzle {
        //     device_name: string,
        //     device_type: number,
        //     device_id: string,
        // }

        const devices: Map<string, Device> = new Map();
        for (let idx in data.devices) {
            const rawData = data.devices[idx];
            console.log('idx: ', idx, '; deviceType: ', rawData['device_type'], '; deviceName: ', rawData['device_name'], '; deviceID: ', rawData['device_id'], '; object_id: ', rawData['object_id'])
            const device = BuildDevice(intToDeviceType(rawData['device_type']), rawData);
            if (device != null) {
                devices.set(device.object_id, device);
            }
        }
        console.log('extracted devices: ', devices)

        return {
            ...s,
            devices: devices,
        }
    }

    constructor(message: string) {
        this.message = message;
    }
}