import { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { NBX } from "../../models/devices/nbx";
import { UBX } from "../../models/devices/ubx";
import { Device } from "../../models/devices/device";
import { DeviceStateOverview } from "../../widgets/dashboard/device_state_overview";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { CVM } from "../../models/devices/cvm";

export function MultiDeviceStateOverview(props: { devices: Map<string, Device> }): JSX.Element {
    const [open, setOpen] = useState<boolean>(true);
    const { dashboardState } = useDashboardState();

    let nbx: NBX[] = [];
    let ubx: UBX[] = [];
    let cvm: CVM[] = [];

    // useEffect(() => {
    //     nbx.length = 0
    //     ubx.length = 0
    //     cvm.length = 0
    dashboardState.devices.forEach((device) => {
        if (device instanceof NBX) nbx.push(device);
        if (device instanceof UBX) ubx.push(device);
        if (device instanceof CVM) cvm.push(device);
    });
    // })

    // console.log(nbx)
    // console.log(ubx)
    // console.log(cvm)


    return (
        <>
            <h2>&Uuml;bersicht <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton> </h2>
            <Collapse in={open}>
                <div className="devices-state-overview">
                    <DeviceStateOverview title='NBX' data={[
                        nbx.length,
                        0,
                        0,
                        0,
                    ]} />
                    <DeviceStateOverview title='UBX' data={[
                        ubx.length,
                        0,
                        0,
                        0,
                    ]} />
                    <DeviceStateOverview title='CVM' data={[
                        cvm.length,
                        0,
                        0,
                        0,
                    ]} />
                    {/* <DeviceStateOverview title='Sirenen' data={null} />
                    <DeviceStateOverview title='CVM-Module' data={[0, 0, 3, 14]} /> */}
                </div>
            </Collapse>
        </>
    )
}