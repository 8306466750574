import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";

interface ModalTextDialogProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    initialText?: string;
    onSubmit: (value: string) => void;
}

export function ModalTextDialog(props: ModalTextDialogProps) {
    const [value, setValue] = useState<string>(props.initialText ?? '');

    function handleClose() {
        props.setIsOpen(false);
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => props.setIsOpen(false)}
            PaperProps={{
                component: 'form',
            }}
            onSubmit={
                (e: React.FormEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    props.onSubmit(value);
                    handleClose();
                }
            }
        >
            <DialogTitle>Name &auml;ndern</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Name &auml;ndern
                </DialogContentText>
                <TextField
                    autoFocus
                    required

                    value={value}
                    onChange={e => setValue(e.target.value)}

                    margin="dense"
                    fullWidth
                    variant="standard"
                    type="text"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained">Abbrechen</Button>
                <Button type="submit" variant="contained">Best&auml;tigen</Button>
            </DialogActions>
        </Dialog>
    )
}