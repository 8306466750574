import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Redirecter() {
    const navigate = useNavigate();

    useEffect(
        () => {
            setTimeout(() =>
                navigate('/dashboard/overview')
                , 300)
        }
        , [navigate])

    return (
        <>
            navigate('/dashboard/overview')
        </>
    )
}

export default Redirecter;