import { LatLng } from "leaflet";
import { Device, DeviceType } from "./device";

export class CVM extends Device {
    /**
     * CVM devices do not have position on their own. 
     * 
     * They might be in a vehicle, which have a UBX.
     * This connection is not build into the dashboard (@21.10.24)
     * @returns undefined
     */
    getPosition(): LatLng | undefined {
        return undefined;
    }

    current: number = 0;
    power: number = 0;
    voltage: number = 0;

    constructor(
        name: string,
        id: string,
        params: {
            current?: number,
            power?: number,
            voltage?: number,

            lastUpdate: number
        }
    ) {
        super(name, DeviceType.cvm, id, Date.now(),);
        if (params.current) this.current = params.current;
        if (params.power) this.power = params.power;
        if (params.voltage) this.voltage = params.voltage;
    }


}