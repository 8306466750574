import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Styling
import './style/App.scss'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoginPage from './pages/login/loginPage';
import PageNotFound from './pages/pageNotFoundPage';
import DashboardPage from './pages/dashboard/dashboardTemplate';
import Alarm from './pages/dashboard/alarm';
import Logout from './widgets/logout';
import Dashboard from './pages/dashboard/dashboard';
import Devices from './pages/dashboard/devices';
import Websocket from './pages/dashboard/websocket';
import Redirecter from './pages/dashboard/redirecter';
import Device from './pages/dashboard/device';
import Widgets from './pages/dashboard/widgets';
import SettingsPage from './pages/dashboard/settings';
import NotificationsPage from './pages/dashboard/notifications';
import { NotificationPage } from './pages/dashboard/notification';
import SelectOrganisationPage from './pages/login/selectOrganisationPage';

// Routing
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        index: true,
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/selectOrganisation',
        element: <SelectOrganisationPage />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/dashboard',
        element: <DashboardPage />,
        children:
          [
            {
              path: 'overview',
              element: <Dashboard />,
              index: true,
            },
            {
              path: 'alarms',
              element: <Alarm />,
            },
            {
              path: 'device/:id',
              element: <Device />,
            },
            {
              path: 'devices',
              element: <Devices />,
            },
            {
              path: 'notification/:databaseID',
              element: <NotificationPage />,
            },
            {
              path: 'notifications',
              element: <NotificationsPage />,
            },
            {
              path: 'settings',
              element: <SettingsPage />,
            },
            {
              path: 'widgets',
              element: <Widgets />,
            },
            {
              path: 'websocket',
              element: <Websocket />,
            },
            {
              path: '*',
              element: <Redirecter />
            }
          ],
      }
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

reportWebVitals();
