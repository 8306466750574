import { Outlet } from "react-router-dom";
import Footer from "../../widgets/footer";
import NavigationBar from "../../widgets/navigationBar";
import DashboardHeader from "./header";

// function DashboardPage(props: DashboardPageProps) {
function DashboardPage() {
    return (
        <div className="dashboard-page">
            <div className="dashboard-page-sidebar">
                <NavigationBar />
            </div>
            <div className="dashboard-page-main">
                <DashboardHeader />
                <main className="dashboard-page-main-content">
                    <Outlet />
                </main>
                <Footer />
            </div>
        </div>
    )
}

export default DashboardPage;