import { TEN_DAYS_IN_MILLISECONDS } from "../constants";
import { log_text } from "../log";

export function isMoreThan10DaysOld(date: number | undefined): boolean {
    // implicitly true, as i cannot compare it
    log_text(date);
    if (date === undefined) return true;
    let now = Date.now();
    let diff = now - date;

    log_text(diff);
    if (diff > TEN_DAYS_IN_MILLISECONDS) {
        return true;
    }
    return false;
}