import { DEBUG_LEVEL } from "./constants";

// [ ] mach des wie hier: https://en.wikipedia.org/wiki/Syslog

export const enum DebugLevels {
    NONE = 0,
    LOG = 1,
    DEBUG = 2,
    ERROR = 3,
    PANIC = 4,
}

export function log_text(log_message: any, log_level: DebugLevels = DebugLevels.DEBUG) {
    if (log_level >= DEBUG_LEVEL) {
        console.log(log_message);
    }
}

export function log_table(log_message: any, log_level: DebugLevels = DebugLevels.DEBUG) {
    if (log_level >= DEBUG_LEVEL) {
        console.table(log_message);
    }
}