import { Box, Button } from "@mui/material";


/**
 * Button, which is positioned in the bottom right corner
 * 
 * @returns 
 */
export default function FloatingButton(props: { label: string, onclick?: () => void }) {
    return (
        <Box
            className="floating-button"
        >
            <Button
                variant="contained"
                onClick={props.onclick}
            >
                {props.label}
            </Button>
        </Box>
    )
}