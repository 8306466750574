import { icon } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

import iconURL from "leaflet/dist/images/marker-icon.png";
import { useEffect, useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { log_text } from "../../log";

type SingleDeviceMapProps = {
    Device: {
        deviceName: string,
        latitude?: number | undefined,
        longitude?: number | undefined,
    }
}

const Recenter = (props: { position: [number, number] }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(props.position);
    }, [props.position, map]);
    return null;
}

export function SingleDeviceMap(props: SingleDeviceMapProps) {
    const [open, setOpen] = useState<boolean>(true);

    log_text(props);

    if (!props.Device.latitude || !props.Device.longitude) return (
        <h1>Keine GPS-Daten verf&uuml;gbar</h1>
    )

    let position: [number, number] = [props.Device.latitude, props.Device.longitude];

    return (
        <>
            <h2>
                Karte
                <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </h2>
            <Collapse in={open}>
                <MapContainer style={{ width: '100%', height: '500px' }} center={position} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position} icon={icon({
                        iconUrl: iconURL,
                        iconAnchor: [10, 41],
                        popupAnchor: [2, -41],
                    })}>
                        <Popup>
                            {props.Device.deviceName}
                        </Popup>
                    </Marker>
                    <Recenter position={position} />
                </MapContainer>
            </Collapse>
        </>
    )
}