import { useState } from "react";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { log_text } from "../../log";

const Websocket = () => {
    const { dashboardState } = useDashboardState();

    const [websocketMessage, setWebsocketMessage] = useState<string>("");

    const sendWebsocketMessage = (message: string) => {
        log_text(message);
        dashboardState.websocket?.send(message);
        setWebsocketMessage('');
    }

    return (
        <div className="dashboard-body">
            Hier kommt dann sowas mit websocket hinn..
            {/* render list of websocket messages */}
            {dashboardState.websocketMessages.map((message, idx) => {
                return <li key={idx}>{message}</li>
            })}
            Blubb
            <p>
                <button onClick={(e) => { alert(dashboardState.websocket); }}>Click me</button>
            </p>

            <p>
                <input type="text" name="websocketMessage" value={websocketMessage} onChange={(e) => setWebsocketMessage(e.target.value)} />
                <input type="button" value="Send WS message" onClick={(e) => sendWebsocketMessage(websocketMessage)} />
            </p>
        </div>
    )
}

export default Websocket;