import { useNavigate } from "react-router-dom";
import { NBX } from "../../models/devices/nbx";
import { isMoreThan10DaysOld } from "../../helper/date";
import { Card, CardActionArea, CardContent, CardHeader, colors } from "@mui/material";
import { Circle } from "@mui/icons-material";

interface NBXWidgetProps {
    model: NBX;
}

export default function NBXWidget(props: NBXWidgetProps) {
    const navigate = useNavigate();

    const lastUpdateString: string =
        isMoreThan10DaysOld(props.model.lastUpdate)
            ? 'nie'
            : new Date(props.model.lastUpdate!).toLocaleString()

    const cardHeader = <div className="device-card-header">
        <Circle fontSize="small" sx={{
            paddingRight: '1em',
            // color: props.model.getColorState(),
            color: colors.red,
        }} />
        {props.model.device_name}
    </div>

    return (
        <Card elevation={4} >
            <CardActionArea sx={{ p: 2 }} onClick={() => navigate(`/dashboard/device/${props.model.object_id}`)}>
                {/* <CardHeader sx={{ color: 'red' }} title={props.model.id.substring(0, 10)} subheader="NBX" /> */}
                <CardHeader title={cardHeader} subheader="NBX" />
                <CardContent>
                    <p>Letztes Update: {lastUpdateString}</p>
                    {
                        // props.model.voltageProperties.map((p, idx) => {
                        // if (p.getDeviceState() === DeviceState.ALARM) {
                        //     return <p style={{ color: 'red' }} key={idx}>{p.name}: {p.value}</p>
                        // }
                        // if (p.getDeviceState() === DeviceState.WARNING) {
                        //     return <p style={{ color: 'orange' }} key={idx}>{p.name}: {p.value}</p>
                        // }
                        //     return <p key={idx}></p>
                        // })
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    )
}