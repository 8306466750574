import { Build, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";

type DeviceTableProps = {
    // title: string
    type: "Telegram" | "Mail"
    // make this more easily to use
    databaseID: string,
    details: [string, any][]
}

export function NotificationTable(props: DeviceTableProps) {
    const [open, setOpen] = useState<boolean>(true);
    const navigate = useNavigate();

    return (
        <>
            <div className="notification-table-header">
                <h2>
                    <IconButton onClick={() => navigate(`/dashboard/notification/${props.databaseID}`)}><Build /></IconButton>
                    {props.type}
                    <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
                </h2>
                <div style={{ flexGrow: 1 }}></div>
                {/* <Button variant="contained" onClick={(e) => { dispatch(new TestNotificationAction(props.databaseID)) }}>Testbenachrichtigung versenden</Button> */}
            </div>

            <Collapse in={open}>
                <Table>
                    <TableBody>

                        {/* <TableRow>
                            <TableCell width={'30%'} align="left">NotificationID</TableCell>
                            <TableCell width={'70%'} align="left">{props.NotificationID}</TableCell>
                        </TableRow> */}
                        {props.details.map((entry, idx) => {
                            return (
                                <TableRow key={idx}>
                                    <TableCell width={'30%'} align="left">{entry[0]}</TableCell>
                                    <TableCell width={'70%'} align="left">{entry[1]}</TableCell>
                                </TableRow>)
                        }
                        )}
                    </TableBody>
                </Table>
            </Collapse>
        </>
    );
}