import { Dispatch, SetStateAction, createContext, useContext } from "react"

export type DebugContextType = {
    debug: boolean | null,
    setDebug: Dispatch<SetStateAction<boolean | null>>,
}

export const DebugContext = createContext<DebugContextType>({
    debug: null,
    setDebug: () => { },
});

export const useDebug = () => useContext(DebugContext);