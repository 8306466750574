import { useDebug } from "../contexts/debugContext";
import NavbarItem from "../pages/dashboard/navbarItem";
import { VerticalSpacer } from "./spacer";
import { AccessAlarm, ExitToApp, Settings, Mail, Devices, Dashboard } from "@mui/icons-material";

function getNavbarSettings(level: number): JSX.Element {
    if (level >= 2) {
        return (
            <>
                <VerticalSpacer height={16} />
                <NavbarItem
                    icon={Settings}
                    path="/dashboard/settings"
                    title="Einstellungen"
                />
            </>
        )
    }
    return (
        <>
        </>
    )
}

function getNavbarNotifications(level: number): JSX.Element {
    if (level >= 2) {
        return (<>
            <VerticalSpacer height={16} />
            <NavbarItem
                icon={Mail}
                path="/dashboard/notifications"
                title="Benachrichtigungen"
            />
        </>)
    }
    return <>
    </>
}


function NavigationBar() {
    let { debug } = useDebug();


    return (
        // <div className="navigation-bar align-items-stretch bg-secondary">
        <div className="bg-dark h-100 w-100 p-0 m-0">
            {/* 
            insert Logo 
            [ ] the logo can be moved into the header to make position better and easier
            */}
            <img
                src="/logo.png"
                style={{ paddingTop: 10, paddingLeft: 30, paddingRight: 30, paddingBottom: 13, height: 'auto', width: 320 }}
                className="justify-content-center" alt="Logo Status3IT"
            />

            {/* space */}
            <VerticalSpacer height={110} />

            {/* Common Nav Elements */}
            {/* // [ ] position text in navigation item */}
            <NavbarItem
                icon={Dashboard}
                path="/dashboard/overview"
                title="Dashboard"
            />
            <VerticalSpacer height={16} />
            <NavbarItem
                icon={Devices}
                path="/dashboard/devices"
                title="Ger&auml;te"
            />
            {/* <VerticalSpacer height={16} />
            <NavbarItem
                icon={AccessAlarm}
                path="/dashboard/alarms"
                title="Alarme"
            />
            <VerticalSpacer height={16} />
            <NavbarItem
                icon={AccessAlarm}
                path="/dashboard/widgets"
                title="Widgets"
            /> */}

            {
                getNavbarSettings(2)
            }
            {
                getNavbarNotifications(2)
            }
            {
                debug ?
                    <>
                        <VerticalSpacer height={16} />
                        <NavbarItem
                            icon={AccessAlarm}
                            path="/dashboard/websocket"
                            title="Websocket"
                        />
                    </>
                    : <></>
            }

            <VerticalSpacer height={16} />
            <NavbarItem
                icon={ExitToApp}
                path="/logout"
                title="Ausloggen"
            />

            {/* Admin Nav Elements if needed */}
        </div>
    )
}

export default NavigationBar;